import React from "react";
// import CardItem from "./CardItem";
import "./Cards.css";
import ContentEditable from "react-contenteditable";
import notionIcon from "../images/NotionIcon.png";

export default class Cards extends React.Component {
  render() {
    // console.log(this.props.items);
    return (
      <div className="cards">
        {this.props.items.map((item) => (
          <CardItem
            item={item}
            title={item.title}
            memo={item.memo}
            pageName={item.pageName}
            pageID={item.pageID}
            blockID={item.blockID}
            itemID={item.itemID}
            originURL={item.originURL}
            newURL={item.newURL}
            view={this.props.view}
            // toggled={this.props.toggled}
            handleDelete={this.props.handleDelete}
            handleUpdateMemo={this.props.handleUpdateMemo}
            handleUpdateTitle={this.props.handleUpdateTitle}
          />
        ))}
      </div>
    );
  }
}

class CardItem extends React.Component {
  constructor(props) {
    super(props);
    this.quickView = (
      <div className="quickView tabcontent">
        <ContentEditable
          className="card--title field"
          contentEditable="True"
          onChange={(e) => this.props.handleUpdateTitle(e, this.props.item)}
          html={this.props.title}
        />
        <ContentEditable
          className="card--memo quick field"
          contenteditable="True"
          onChange={(e) => this.props.handleUpdateMemo(e, this.props.item)}
          html={this.props.memo}
        />
        <div className="card--actions">
          <CopyBtn newURL={this.props.newURL} />
          <DeleteBtn
            itemID={this.props.itemID}
            // handleDelete={() => {
            //   this.props.handleDelete(this.props.item.itemID);
            // }}
            handleDelete={() => {
              this.props.handleDelete(this.props.item);
            }}
          />
        </div>
      </div>
    );
    this.detailedView = (
      <div className="detailedView tabcontent">
        <div className="card--title--pair">
          <p className="card--label">BLOCK NAME</p>
          <ContentEditable
            className="card--title field"
            contentEditable="True"
            onChange={(e) => this.props.handleUpdateTitle(e, this.props.item)}
            html={this.props.title}
          />
        </div>
        <div className="card--notes card--pair">
          <p className="card--label">NOTES</p>
          <ContentEditable
            className="card--memo field"
            contenteditable="True"
            onChange={(e) => this.props.handleUpdateMemo(e, this.props.item)}
            html={this.props.memo}
            // html='Add your block description here'
          />
        </div>
        <hr class="solid--line" />

        <div className="card--page--name--id card--pair">
          <p className="card--label">PAGE NAME & ID</p>
          <a
            className="card--text anchored--page field"
            href={this.props.originURL}
            target="_blank"
            rel="noreferrer"
          >
            {this.props.pageName}
            <img src={notionIcon} alt="Notion Icon" className="notion--icon" />
          </a>
          <p className="card--text field">{this.props.pageID}</p>
        </div>
        <div className="card--block--id card--pair">
          <p className="card--label">BLOCK ID</p>
          <p className="card--text field">{this.props.blockID}</p>
        </div>
        <div className="card--newURL card--pair">
          <p className="card--label">REUSABLE BLOCK LINK</p>
          <p className="card--text field">{this.props.newURL}</p>
        </div>
        <div className="card--actions">
          <CopyBtn newURL={this.props.newURL} />
          <DeleteBtn
            handleDelete={() => {
              this.props.handleDelete(this.props.item);
            }}
            itemID={this.props.itemID}
          />
        </div>
      </div>
    );
    this.trashView = (
      <div className="quickView tabcontent">
        <ContentEditable
          className="card--title field"
          contentEditable="True"
          onChange={(e) => this.props.handleUpdateTitle(e, this.props.item)}
          html={this.props.title}
        />
        <ContentEditable
          className="card--memo quick field"
          contenteditable="True"
          onChange={(e) => this.props.handleUpdateMemo(e, this.props.item)}
          html={this.props.memo}
        />
        <div className="card--actions">
          <CopyBtn newURL={this.props.newURL} />
          <RestoreBtn
            handleDelete={() => {
              this.props.handleDelete(this.props.item);
            }}
            itemID={this.props.itemID}
          />
        </div>
      </div>
    );
  }
  render() {
    return (
      <div className="card--item">
        {/* {this.props.toggled ? this.detailedView : this.quickView} */}
        {this.props.view === "quickView" ? this.quickView : this.detailedView}
      </div>
    );
  }
}

class CopyBtn extends React.Component {
  handleCopy(content) {
    navigator.clipboard.writeText(content);
    alert("Copied the converted block link!");
    // this.setState({ btnText: "COPIED" });
  }

  render() {
    return (
      <div
        className="copy--btn field"
        onClick={() => this.handleCopy(this.props.newURL)}
      >
        {/* {this.state.btnText} */}
        COPY LINK
      </div>
    );
  }
}

class DeleteBtn extends React.Component {
  render() {
    return (
      <span
        className="delete--btn field"
        // onClick={this.props.handleDelete(this.props.itemID)}
        // onClick={() => this.props.handleDelete(this.props.itemID)}
        onClick={() => this.props.handleDelete(this.props.item)}
      >
        DELETE
      </span>
    );
  }
}

class RestoreBtn extends React.Component {
  render() {
    return (
      <span
        className="delete--btn field"
        // onClick={this.props.handleDelete(this.props.itemID)}
        // onClick={() => this.props.handleDelete(this.props.itemID)}
        onClick={() => this.props.handleDelete(this.props.item)}
      >
        RESTORE
      </span>
    );
  }
}
