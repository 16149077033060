import React from "react";
import Button from "../Button";
import { Link } from "react-router-dom";
import Cards from "../Cards";
// import Toggle from "../Toggle";
import Guide from "../Guide";
import Tabs from "../Tabs";

import "./Convert.css";

export default class Convert extends React.Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      toggled: false,
      activeTab: "",
    };
    this.handleToggle = this.handleToggle.bind(this);
    this.openTab = this.openTab.bind();
  }

  handleToggle() {
    // console.log("Toggle triggered");
    this.setState({
      toggled: !this.state.toggled,
    });
  }

  openTab = (event, tabName) => {
    var i, tabcontents, tablinks;

    // update tab contents
    tabcontents = document.getElementsByClassName("tabcontents");
    for (i = 0; i < tabcontents.length; i++) {
      tabcontents[i].style.display = "none";
    }
    document.getElementById(tabName).style.display = "block";

    // update button states
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    event.currentTarget.className += " active";

    // update tab states
    this.setState({
      activeTab: tabName,
    });
  };

  componentDidMount() {
    this.textInput.current.focus();
  }

  render() {
    return (
      <div className="convert">
        <h2 className="convert--headline">Convert to a Reusable Block</h2>
        <div className="convert--section">
          <div className="inputSection">
            <input
              id="blockLinkInput"
              onChange={this.props.handleChange}
              value={this.props.text}
              ref={this.textInput}
              placeholder="Your block link..."
            />
            <div className="submit--btn">
              <Button onClick={this.props.handleSubmit}>Get Link</Button>
            </div>
          </div>
          <p className="redirect--to--about">
            <Link className="block--hyperlink" to="/about">
              {" "}
              What is a reusable block?
            </Link>{" "}
          </p>
          <Guide />
        </div>
        <hr className="division--line" />
        <h3>Your Blocks Collection</h3>
        <h4 className="collectionIntro">
          Rename your blocks, customize notes, and manage added blocks below
        </h4>
        <Tabs
          tab1="Quick View"
          tab2="Detailed View"
          tab3="Deleted"
          openTab={this.openTab}
          tab1Content={
            <div className="collection quickView">
              <div className="collection--cards">
                <Cards
                  items={this.props.items.filter(
                    (item) => item.display === true
                  )}
                  toggled={this.state.toggled}
                  handleDelete={this.props.handleDelete}
                  handleUpdateTitle={this.props.handleUpdateTitle}
                  handleUpdateMemo={this.props.handleUpdateMemo}
                  view="quickView"
                />
              </div>
            </div>
          }
          tab2Content={
            <div className="collection detailedView">
              <div className="collection--cards">
                <Cards
                  items={this.props.items.filter(
                    (item) => item.display === true
                  )}
                  toggled={this.state.toggled}
                  handleDelete={this.props.handleDelete}
                  handleUpdateTitle={this.props.handleUpdateTitle}
                  handleUpdateMemo={this.props.handleUpdateMemo}
                  view="detailedView"
                />
              </div>
            </div>
          }
          tab3Content={
            <div className="collection detailedView">
              <div className="collection--cards">
                <Cards
                  items={this.props.items.filter(
                    (item) => item.display === false
                  )}
                  toggled={this.state.toggled}
                  handleDelete={this.props.handleDelete}
                  handleUpdateTitle={this.props.handleUpdateTitle}
                  handleUpdateMemo={this.props.handleUpdateMemo}
                  view="quickView"
                />
              </div>
            </div>
          }
        />
      </div>
    );
  }
}
