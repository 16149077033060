import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./Navbar.css";

// function tabSelection(event, tabName) {
//   var i, tablinks;

//   tablinks = document.getElementsByClassName("nav-item");
//   for (i = 0; i < tablinks.length; i++) {
//     tablinks[i].className = tablinks[i].className.replace(" active", "");
//   }

//   event.currentTarget.className += " active";
// }

export default function Navbar() {
  return (
    <div>
      <nav className="navbar">
        <div className="content--container">
          <Link to="/about" className="navbar-logo">
            <i class="fas fa-link"></i> &#8239; NotionLinks
          </Link>
          <div className="nav-menu">
            <NavLink
              className="nav-item convert"
              exact
              to="/"
              activeClassName="active"
              // onClick={(e) => tabSelection(e, "convert")}
            >
              Convert
            </NavLink>
            <NavLink
              className="nav-item about"
              to="/about"
              activeClassName="active"
              // onClick={(e) => tabSelection(e, "about")}
            >
              About
            </NavLink>
          </div>
        </div>
      </nav>
    </div>
  );
}
