import React from "react";
import copy from "../images/Copy.gif";
import convert from "../images/Convert.gif";
import insert from "../images/Insert.gif";
import "./Guide.css";

export default function Guide() {
  return (
    <div className="guide--steps">
      <div className="step1">
        <GuideStep
          number="1"
          title="Copy"
          memo="Right-click your targeted block, choose “Copy Link”"
          src={copy}
          alt="copyDemo"
        />
      </div>
      <div className="step2">
        <GuideStep
          number="2"
          title="Convert"
          memo="Paste your block link to notionlinks.com and convert"
          src={convert}
          alt="convertDemo"
        />
      </div>
      <div className="step3">
        <GuideStep
          number="3"
          title="Insert"
          memo="Paste to your target position in a page and choose “Link to Page”"
          src={insert}
          alt="insertDemo"
        />
      </div>
    </div>
  );
}

function GuideStep(props) {
  return (
    <div className="guide--step">
      <div className="guide--step--words">
        <span className="number--big">{props.number}</span>
        <div className="step--intro">
          <span className="step--title">{props.title}</span>
          <p className="step--memo">{props.memo}</p>
        </div>
      </div>
      <div className="guide--step--image gifs">
        <img src={props.src} alt={props.alt} className="guide--image--src" />
      </div>
    </div>
  );
}
