import React from "react";
import Button from "../Button";
import "../../App.css";
import "./Home.css";
import Guide from "../Guide";
import demo from "../../images/MainDemo.gif";

function Home() {
  return (
    <div className="home">
      <div className="landing--section">
        <div className="intro">
          <h1>
            Edit one block, <br /> sync everywhere.
          </h1>
          <p>
            NotionLinks helps you reuse the same Notion block in multiple pages{" "}
            <br /> and synchronize your edits across the workspace.{" "}
          </p>
        </div>
        <div className="start--button">
          <Button className="btns" path="/">
            Get Started
          </Button>
        </div>
        <div className="demoBackground">
          <img
            src={demo}
            alt="NotionLinks Demo"
            className="demo--gif--src gifs"
          />
        </div>
        <hr className="division--line" />
        <div className="guide">
          <h2>How to Use NotionLinks?</h2>
          <Guide />
          <div className="start--button">
            <Button className="btns" path="/">
              Get Started
            </Button>
          </div>
        </div>
        <div className="placeHolder"></div>
      </div>
    </div>
  );
}

export default Home;
