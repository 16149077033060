import React from "react";
import "./Toggle.css";

class Toggle extends React.Component {
  render() {
    return (
      <div className="toggle--container">
        <label className="toggle--label" for="toggle">
          Detail View
        </label>
        <label class="switch">
          <input
            type="checkbox"
            name="toggle"
            checked={this.props.toggled}
            onChange={this.props.handleToggle}
          />
          <span class="slider round"></span>
        </label>
      </div>
    );
  }
}

export default Toggle;
