import React from "react";
import "./App.css";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import Home from "./Components/pages/Home";
import Convert from "./Components/pages/Convert";
import Collection from "./Components/pages/Collection";
import Navbar from "./Components/Navbar";
import Footer from "./Components/Footer";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // Initialize with contents from local storage
      items: localStorage.getItem("links")
        ? JSON.parse(localStorage.getItem("links"))
        : [],
      text: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleUpdateMemo = this.handleUpdateMemo.bind(this);
  }

  handleChange(e) {
    this.setState({ text: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.text.length === 0) {
      return;
    }
    var newItem = {
      title: "new title",
      memo: "new memo text (new URL by default)",
      pageName: "page Name",
      pageID: "page ID",
      blockID: "block ID",
      originURL: "original URL",
      newURL: "converted URL",
      display: true,
      itemID: Date.now(),
    };

    newItem.originURL = this.state.text;
    newItem = {
      ...newItem,
      pageName: newItem.originURL.substring(22, newItem.originURL.length - 66),
      pageID: newItem.originURL.substring(
        newItem.originURL.length - 65,
        newItem.originURL.length - 33
      ),
      blockID: newItem.originURL.substring(newItem.originURL.length - 32),
    };
    newItem = {
      ...newItem,
      title: `Block from ${newItem.pageName.replace(/-/g, " ")}`,
      newURL: `https://www.notion.so/${newItem.pageName}-${newItem.blockID}`,
    };
    newItem.memo = newItem.newURL;

    this.setState((state) => ({
      items: state.items.concat(newItem),
      text: "",
    }));

    navigator.clipboard.writeText(newItem.newURL);

    alert("Copied the converted block link!");

    // console.log("handleSubmit is called");
    // console.log(`Current items are ${JSON.stringify(this.state.items)}`);
    // console.log(localStorage["links"]);
  }

  // handleDelete(itemID) {
  //   this.setState((state) => ({
  //     items: state.items.filter((item) => item.itemID !== itemID),
  //   }));
  // }

  handleDelete(item) {
    const items = this.state.items;
    const index = this.state.items.indexOf(item);
    console.log({ index });
    items[index].display = !items[index].display;
    this.setState(() => ({
      items: items,
    }));
    // this.forceUpdate();
    // console.log("delete executed");
  }

  handleUpdateTitle(e, item) {
    const items = this.state.items;
    const index = this.state.items.indexOf(item);
    items[index].title = e.target.value;
    this.setState(() => ({
      items: items,
    }));
  }

  handleUpdateMemo(e, item) {
    const items = this.state.items;
    const index = this.state.items.indexOf(item);
    items[index].memo = e.target.value;
    this.setState(() => ({
      items: items,
    }));
  }

  componentDidUpdate() {
    this.textArea.select();
    document.execCommand("copy");
  }

  render() {
    // Store to local storage
    localStorage.setItem("links", JSON.stringify(this.state.items));
    // console.log(this.state.items);
    return (
      <BrowserRouter>
        <div className="app">
          <Navbar />
          <Switch>
            <Route path="/about">
              <Home />
            </Route>
            <Route exact path="/">
              <Convert
                items={this.state.items}
                text={this.state.text}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                handleDelete={this.handleDelete}
                handleUpdateTitle={this.handleUpdateTitle}
                handleUpdateMemo={this.handleUpdateMemo}
              />
            </Route>
            <Route path="/collection">
              <Collection
                items={this.state.items}
                text={this.state.text}
                handleChange={this.handleChange}
                handleSubmit={this.handleSubmit}
                handleDelete={this.handleDelete}
                handleUpdateTitle={this.handleUpdateTitle}
                handleUpdateMemo={this.handleUpdateMemo}
              />
            </Route>
          </Switch>
          <form>
            <textarea
              className="forClipboard"
              ref={(textarea) => (this.textArea = textarea)}
              value={
                this.state.items.length === 0
                  ? "none"
                  : this.state.items[this.state.items.length - 1].newURL
              }
            />
          </form>
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}
