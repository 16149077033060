import React from "react";
import "./Tabs.css";

export default class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "",
    };
  }

  render() {
    return (
      <div className="tabs">
        <div className="tabButtons">
          <Tab
            className="tablinks active"
            tabName={this.props.tab1}
            openTab={this.props.openTab}
          />
          <Tab
            className="tablinks"
            tabName={this.props.tab2}
            openTab={this.props.openTab}
          />
          <Tab
            className="tablinks"
            tabName={this.props.tab3}
            openTab={this.props.openTab}
          />
        </div>
        <div className="tabContents">
          <div className="tabcontents" id={this.props.tab1}>
            {this.props.tab1Content}
          </div>
          <div
            className="tabcontents"
            id={this.props.tab2}
            // To hide second tab upon first rendering
            style={{ display: "none" }}
          >
            {this.props.tab2Content}
          </div>
          <div
            className="tabcontents"
            id={this.props.tab3}
            // To hide third tab upon first rendering
            style={{ display: "none" }}
          >
            {this.props.tab3Content}
          </div>
        </div>
      </div>
    );
  }
}

function Tab(props) {
  return (
    <div>
      <button
        className={props.className}
        onClick={(e) => {
          props.openTab(e, props.tabName);
        }}
      >
        {props.tabName}
      </button>
    </div>
  );
}
