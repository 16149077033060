import React from "react";
// import BlockLinkList from "../BlockLinkList";
import Cards from "../Cards";
import Toggle from "../Toggle";
import "./Collection.css";

export default class Collection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggled: false,
    };
    this.handleToggle = this.handleToggle.bind(this);
  }

  handleToggle() {
    // console.log("Toggle triggered");
    this.setState({
      toggled: !this.state.toggled,
    });
  }

  render() {
    return (
      <div className="collection">
        <h3 className="collection--headline">Your Blocks Collection</h3>
        <div className="detail--view--toggle">
          <Toggle
            toggled={this.state.toggled}
            handleToggle={this.handleToggle}
          />
        </div>
        <div className="collection--cards">
          <Cards
            items={this.props.items}
            toggled={this.state.toggled}
            handleDelete={this.props.handleDelete}
            handleUpdateTitle={this.props.handleUpdateTitle}
            handleUpdateMemo={this.props.handleUpdateMemo}
          />
        </div>
      </div>
    );
  }
}
