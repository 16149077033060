import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer">
      <p>
        NotionLinks is made with &#8239;
        <i className="heart" class="fas fa-heart"></i> &#8239; by &#8239;
        <a
          className="app-link"
          href="https://alanz.me/Hi-this-is-Alan-Z-b0a69f2dd8da4806b15c37de56c85aea"
          target="_blank"
          rel="noopener noreferrer"
        >
          Alan Z
        </a>
      </p>
    </div>
  );
}

export default Footer;
